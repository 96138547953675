.features-tiles-item-image {
	display: inline-flex;
	width: 64px;
	height: 64px;
	border-radius: 50%;
	padding: 16px;
	align-items: center;
	background-color: get-color(primary, 3);

	svg {
		color: get-color(light, 1);
	}
}
